import { Component, Vue, Watch } from 'vue-property-decorator';
import Layout from '@/components/layouts/main.vue';
import PageTitle from '@/components/ui/page-title/pageTitle.vue';
import { AgGridVue } from 'ag-grid-vue';
import Box from '@/components/ui/box/box.vue';
import BoxHeader from '@/components/ui/box/box-header/boxHeader.vue';
import GridFilter from '@/components/ui/grid-filter/gridFilter.vue';
import { AgGridHelper } from '@/helpers/ag-grid/ag-grid.helper';
import { Action, Getter } from 'vuex-class';
import { ListResponseType } from '@/types/response/list-response.type';
import { ICallbackAdditionalParams, ICallbackGeneralParams } from '@/helpers/ag-grid/ag-grid-datasource';
import { ColDef, GridOptions, ICellRendererParams, ValueFormatterParams } from 'ag-grid-community';
import { StayRequest } from '@/types/request/stay-request.types';
import { StayType } from '@/types/api/stay/stay.type';
import { debounceFilter, hasFilter, setEmptyToNull } from '@/helpers/filter.helper';
import visibleHeader from '@/components/ui/ag-grid/header-components/visible-header/visibleHeader.vue';

const title = 'stay.title';

@Component({
  page: {
    title
  },
  components: {
    Layout,
    PageTitle,
    AgGridVue,
    Box,
    BoxHeader,
    GridFilter
  },
})
export default class Stays extends Vue {
  title = title;
  agGrid: AgGridHelper | null = null;
  filter: any = this.initFilter();

  @Action('getStays', { namespace: 'stay' })
  getStays!: (params: StayRequest) => Promise<ListResponseType<StayType>>;

  @Getter('getCurrentLang', { namespace: 'lang' })
  currentLang!: string;

  @Watch('currentLang')
  resetGridHeader(newValue: string, oldValue: string) {
    if (this.agGrid && newValue !== oldValue) {
      this.agGrid.getGridApi()!.refreshHeader();
    }
  }

  created(): void {
    this.debounceApplyFilter = debounceFilter(this.debounceApplyFilter);
  }

  beforeMount() {
    this.agGrid = new AgGridHelper(this.initGridOptions())
      .enableAutoSizeColumns(false)
      .enableInfinity(this.gridChangeCallback)
      .build();
  }

  gridChangeCallback(general: ICallbackGeneralParams, additional: ICallbackAdditionalParams) {
    additional.api.showLoadingOverlay();

    return this.getStays({
      take: general.limit,
      skip: general.offset,
      ...{
        ...setEmptyToNull(this.filter),
        size: '640x400'
      },
    })
      .then(response => {
        additional.api.hideOverlay();
        return {
          result: response.items,
          count: response.paging.totalCount
        }
      });
  }

  initGridOptions(): GridOptions {
    return {
      columnDefs: this.initColumnDef(),
      enableSorting: false,
      rowHeight: 100,
      headerHeight: 39,
      suppressHorizontalScroll: true,
    }
  }

  initColumnDef(): ColDef[] {
    return [
      {
        headerValueGetter: () => this.$t('stay.nameAddress'),
        field: 'name',
        width: 400,
        minWidth: 400,
        cellRenderer: 'nameAddressCellRenderer',
        cellRendererParams: {
          imageUrl: (params: ICellRendererParams) => params.data?.images[0],
          text: (params: ICellRendererParams) => params.data?.hotelName,
          address: (params: ICellRendererParams) => params.data?.hotelAddress,
        }
      },
      {
        headerValueGetter: () => this.$t('stay.distance'),
        field: 'distance',
      },
      {
        headerValueGetter: () => this.$t('stay.price'),
        field: 'price',
        valueFormatter: (params: ValueFormatterParams) => params.data && params.data.price.toFixed(2)
      },
    ];
  }

  debounceApplyFilter() {
    this.applyFilter();
  }

  applyFilter() {
    if (this.agGrid) {
      this.agGrid.refreshInfinity();
    }
  }

  resetFilter() {
    this.filter = this.initFilter();
    if (this.agGrid) {
      this.agGrid.refreshInfinity();
    }
  }

  initFilter() {
    return {
      long: null,
      lat: null,
      radius: null,
      name: null
    };
  }

  get isFilter() {
    return hasFilter(this.filter);
  }
}
